<template>
  <div class="map" :style="{backgroundImage: 'url(' + mapbg + ')'}">
    <div class="gif-warp">
      <img class="path" src="@/assets/roa.png" alt="" srcset="">
      <img class="center" src="@/assets/map/1.png" alt="" srcset="">
      <div class="sx-warp" @click="navigateToDetailMap('数学馆')">
        <img class="sx-icon name" src="@/assets/map/13.png" alt="" srcset="">
        <img class="sx-gif" src="@/assets/gif/sx.gif" alt="" srcset="">
      </div>
      <div class="kx-warp" @click="navigateToDetailMap('科学馆')">
        <img class="kx-icon name" src="@/assets/map/15.png" alt="" srcset="">
        <img class="kx-gif" src="@/assets/gif/kx.gif" alt="" srcset="">
      </div>
      <div class="yy-warp" @click="navigateToDetailMap('英语馆')">
        <img class="yy-icon name" src="@/assets/map/14.png" alt="" srcset="">
        <img class="yy-gif" src="@/assets/gif/yy.gif" alt="" srcset="">
      </div>
      <div class="ddyfz-warp" @click="navigateToDetailMap('道德与法治馆')">
        <img class="ddyfz-icon name" src="@/assets/map/11.png" alt="" srcset="">
        <img class="ddyfz-gif" src="@/assets/gif/ddyfz.gif" alt="" srcset="">
      </div>
      <div class="yw-warp" @click="navigateToDetailMap('语文馆')">
        <img class="yw-icon name" src="@/assets/map/12.png" alt="" srcset="">
        <img class="yw-gif" src="@/assets/gif/yw.gif" alt="" srcset="">
      </div>
      <div class="bd-warp" @click="navigateToDetailMap('班队活动馆')">
        <img class="bd-icon name" src="@/assets/map/16.png" alt="" srcset="">
        <img class="bd-gif" src="@/assets/gif/bd.gif" alt="" srcset="">
      </div>
      <div class="ty-warp" @click="navigateToDetailMap('体育馆')">
        <img class="ty-icon name" src="@/assets/map/19.png" alt="" srcset="">
        <img class="ty-gif" src="@/assets/gif/ty.gif" alt="" srcset="">
      </div>
      <div class="mus-warp" @click="navigateToDetailMap('音乐馆')">
        <img class="mus-icon name" src="@/assets/map/20.png" alt="" srcset="">
        <img class="mus-gif" src="@/assets/gif/mus.gif" alt="" srcset="">
      </div>
      <div class="ms-warp" @click="navigateToDetailMap('美术馆')">
        <img class="ms-icon name" src="@/assets/map/18.png" alt="" srcset="">
        <img class="ms-gif" src="@/assets/gif/ms.gif" alt="" srcset="">
      </div>
      <div class="zhsj-warp" @click="navigateToDetailMap('综合实践活动馆')">
        <img class="zhsj-icon name" src="@/assets/map/17.png" alt="" srcset="">
        <img class="zhsj-gif" src="@/assets/gif/zhsj.gif" alt="" srcset="">
      </div>
    </div>
    <div class="map-left">
      <div class="hot">
        <img src="@/assets/map/21.png" alt="" srcset="" />
        <p>热门馆</p>
      </div>
      <ul class="hot-menu">
        <li class="hot-menu-item" v-for="item in hallList" :key="item.HallName" @click="navigateToDetail(item.HallId)">
          {{ item.HallName }}
        </li>
      </ul>
    </div>
    <div class="map-left-btm">
      <!-- <p>累计观看</p>
      <p class="v-num">123434</p> -->
      <p class="surport">技术支持：重庆骏懋教育科技有限公司</p>
    </div>
    <div id="map-right-top">
      <!-- <div class="search-warp">
        <input class="search-input" placeholder="输入关键字" type="text" />
        <img class="search-icon" src="@/assets/map/25.png" alt="" srcset="" />
      </div>
      <div class="full-warp">
        <img src="@/assets/map/24.png" alt="" srcset="" />
        <img src="@/assets/map/23.png" alt="" srcset="" />
      </div> -->
    </div>
    <div id="map-right-btm">
      <!-- <ul class="share-list">
        <li class="share-item">
          <img src="@/assets/map/28.png" alt="" srcset="" />
          <p>123</p>
        </li>
        <li class="share-item">
          <img src="@/assets/map/27.png" alt="" srcset="" />
          <p>123</p>
        </li>
      </ul>
      <img class="vol" src="@/assets/map/26.png" alt="" srcset="" /> -->
    </div>
  </div>
</template>

<script>
import { getAllHall } from "@/api/website";
import mapbg from "@/assets/mapbg.png"
export default {
  data() {
    return {
      hallList: [],
      mapbg:mapbg
    };
  },
  mounted() {
    this.requestInfo();
  },
  methods: {
    requestInfo() {
      getAllHall().then((res) => {
        console.log(res);
        this.hallList = res.data.hallList;
      });
    },
    navigateToDetail(hallId){
        this.$router.push({name:'hall', query:{hallId:hallId}})
    },
    navigateToDetailMap(title) {
      let arr = this.hallList.filter(item=>{
        return (item.HallName.indexOf(title) != -1)
      })
      let item = arr[0]
      this.navigateToDetail(item.HallId)
    }
  },
};
</script>

<style lang="less">
.map {
    position: relative;
    min-height: 800Px;
    background-size: 100% 100%;
    padding-top: 30Px;
    .gif-warp {
      width: 1000Px;
      height: 674Px;
      margin: 0 auto;
      padding-top: 50Px;
      position: relative;
      .path {
        position: absolute;
        width: 750Px;
        height: 502Px;
        left: 100Px;
        top:80Px;
        z-index: 100;
      }
      .center {
        position: absolute;
        z-index: 101;
        width: 392Px;
        height: 390Px;
        left: 196Px;
        top: 100Px;
      }
      .sx-warp {
        width: 216Px;
        height: 216Px;
        position: absolute;
        right: 230Px;
        top: 180Px;
        cursor: pointer;
        z-index: 102;
        .sx-gif {
          width: 216Px;
          height: 216Px;
        }
        .sx-icon {
          position: absolute;
          left: 70Px;
          width: 37Px;
          height: 54Px;
        }
      }
      .kx-warp {
        width: 150Px;
        height: 150Px;
        position: absolute;
        right: 120Px;
        top: 80Px;
        cursor: pointer;
        z-index: 102;
        .kx-gif {
          width: 150Px;
          height: 150Px;
        }
        .kx-icon {
          position: absolute;
          width: 37Px;
          height: 54Px;
          left: 60Px;
          top: -20Px;
        }
      }
      .yy-warp {
        width: 200Px;
        height: 200Px;
        position: absolute;
        right: 100Px;
        top: 430Px;
        cursor: pointer;
        z-index: 102;
        .yy-gif {
          width: 180Px;
          height: 180Px;
        }
        .yy-icon {
          position: absolute;
          width: 37Px;
          height: 54Px;
          right: 60Px;
          top:-50Px;
        }
      }
      .ddyfz-warp {
        width: 200Px;
        height: 200Px;
        position: absolute;
        right: 390Px;
        top:450Px;
        cursor: pointer;
        z-index: 102;
        .ddyfz-gif {
          width: 160Px;
          height: 160Px;
        }
        .ddyfz-icon {
          position: absolute;
          width: 82Px;
          height: 78Px;
          left: 60Px;
          top: -60Px;
        }
      }
      .yw-warp {
        width: 200Px;
        height: 200Px;
        position: absolute;
        left:150Px;
        top:460Px;
        cursor: pointer;
        z-index: 102;
        .yw-gif {
          width: 170Px;
          height: 170Px;
        }
        .yw-icon {
          position: absolute;
          width: 37Px;
          height: 54Px;
          left: 120Px;
          top: -30Px;
        }
      }
      .bd-warp {
        position: relative;
        width: 150Px;
        height: 150Px;
        position: absolute;
        left:100Px;
        top:330Px;
        cursor: pointer;
        z-index: 102;
        .bd-gif {
          width: 130Px;
          height: 130Px;
        }
        .bd-icon {
          position: absolute;
          width: 37Px;
          height: 54Px;
          left: 70Px;
          top: -40Px;
        }
      }
      .ty-warp {
        position: relative;
        width: 170Px;
        height: 170Px;
        position: absolute;
        left:20Px;
        top:110Px;
        cursor: pointer;
        z-index: 102;
        .ty-gif {
          width: 170Px;
          height: 170Px;
        }
        .ty-icon {
          position: absolute;
          width: 37Px;
          height: 54Px;
          left: 70Px;
          top: -50Px;
        }
      }
      .mus-warp {
        position: relative;
        width: 140Px;
        height: 140Px;
        position: absolute;
        left:200Px;
        top:20Px;
        cursor: pointer;
        z-index: 102;
        .mus-gif {
          width: 140Px;
          height: 140Px;
        }
        .mus-icon {
          position: absolute;
          width: 37Px;
          height: 54Px;
          left: 70Px;
          top: -30Px;
        }
      }
      .ms-warp {
        position: relative;
        width: 160Px;
        height: 160Px;
        position: absolute;
        right:370Px;
        top:60Px;
        cursor: pointer;
        z-index: 102;
        .ms-gif {
          width: 140Px;
          height: 140Px;
        }
        .ms-icon {
          position: absolute;
          width: 37Px;
          height: 54Px;
          left: 90Px;
          top: -40Px;
        }
      }
      .zhsj-warp {
        position: relative;
        width: 140Px;
        height:140Px;
        position: absolute;
        right:60Px;
        top:230Px;
        cursor: pointer;
        z-index: 102;
        .zhsj-gif {
          width: 140Px;
          height: 140Px;
        }
        .zhsj-icon {
          position: absolute;
          width: 61Px;
          height: 67Px;
          left: 90Px;
          top: -20Px;
        }
      }

      .name {
        animation: heart 1.3s ease-in-out 2.7s infinite alternate;
      }
        //用transform就流畅了
        @keyframes heart{
          from{transform:translate(0,0)}
          to{transform:translate(0,6px)}
        }

    }
    .map-left {
        position: absolute;
        left: 42Px;
        top: 30Px;
        .hot {
            cursor: pointer;
            text-align: center;
            font-size: 14Px;
            font-weight: bold;
        }
        .hot-menu {
            padding: 0;
            .hot-menu-item {
                margin: 8Px 0;
                cursor: pointer;
                font-size: 14Px;
                font-weight: bold;
                width: 100Px;
                text-align: center;
            }
        }
    }
    .map-left-btm {
        position: absolute;
        left: 25Px;
        bottom: 30Px;
        p {
            margin: 0;
            font-size: 16Px;
            color: #1E1E1E;
            font-weight: bold;
        }
        .surport {
            padding-top: 14Px;
            font-size: 12Px;
        }
    }
    #map-right-top {
        position: absolute;
        right: 30Px;
        top: 30Px;
        display: flex;
        justify-content: flex-end;
        img {
            display: block;
            margin: 0;
        }
        .search-warp {
            display: flex;
            .search-input {
                width: 164Px;
                height: 36Px;
                border: none;
                outline: none;
                background: #0778D4;
                padding-left: 30Px;
                color: white;
                font-size: 14Px;
            }
            .search-input:-ms-input-placeholder {
                color: white;
            }/* Internet Explorer 10+ */

            .search-input::-webkit-input-placeholder {
                color: white;
            }/* WebKit browsers */

            .search-input::-moz-placeholder {
                color: white;
            }/* Mozilla Firefox 4 to 18 */

            .search-input::-moz-placeholder {
                color: white;
            }/* Mozilla Firefox 19+ */
            .search-input:focus {
                border: none;
            }
            .search-icon {
                width: 36Px;
                height: 36Px;
                cursor: pointer;
            }
        }
        .full-warp {
            margin-left: 10Px;
            display: flex;
            img {
                width: 36Px;
                height: 36Px;
                cursor: pointer;
            }
        }
    }
    #map-right-btm {
        position: absolute;
        right: 30Px;
        bottom: 30Px;
        display: flex;
        justify-content: flex-end;
        .share-list {
            padding: 0;
            display: flex;
            .share-item {
                text-align: center;
                margin-left: 15Px;
                cursor: pointer;
                p {
                    text-align: center;
                    font-size: 12Px;
                    margin: 0;
                }
            }
        }
        .vol {
            display: block;
            width: 36Px;
            height: 36Px;
            margin-left: 100Px;
            cursor: pointer;
        }
    }
}
</style>