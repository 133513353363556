import request from '../utils/request'

const apis = {
    GetAllHall: '/api/MyLesson/GetAllHall',
    GetHallById: '/api/MyLesson/GetHallById',
    GetLessonInfo: '/api/MyLesson/GetLessonInfo',
    BuyLesson: '/api/MyLesson/BuyLesson',
    CopyUnit2MyUnitBat: '/api/MyLesson/CopyUnit2MyUnitBat',
    GetMoveLesson: '/api/MyLesson/GetMoveLesson',
    UpdateBuyLesson: '/api/MyLesson/UpdateBuyLesson',
}


/**
 * 获取所有课程馆
 * @param {*} parameter 
 */
export function getAllHall(parameter){
    return request({
        url: apis.GetAllHall,
        method: 'get',
        data: parameter
    })
}

/**
 * 依据课程馆ID获取课程馆信息
 * @param {hallId:0} parameter 
 */
export function getHallById(parameter){
    return request({
        url: apis.GetHallById,
        method: 'post',
        data: parameter
    })
}

/**
 * 获取课程信息（演示版仅一课时）
 * @param {lessonId:0} parameter 
 */
export function getLessonInfo(parameter){
    return request({
        url: apis.GetLessonInfo,
        method: 'post',
        data: parameter
    })
}

/**
 * 买课 （演示版，课程馆下全部课程）
 * @param {hallId:0,lessonId:0} parameter 
 */
export function buyLesson(parameter){
    return request({
        url: apis.BuyLesson,
        method: 'post',
        data: parameter
    })
}

/**
 * 课程详情购买
 * @param {hallId:0,lessonId:0} parameter 
 */
export function updateBuyLesson(parameter){
    return request({
        url: apis.UpdateBuyLesson,
        method: 'post',
        data: parameter
    })
}



/**
 * 依据单元id加入我的课程库
 * @param {hallId:0,grade:0} parameter 
 */
export function copyUnit2MyUnitBat(parameter){
    return request({
        url: apis.CopyUnit2MyUnitBat,
        method: 'post',
        data: parameter
    })
}


/**
 * 获取更多课程
 * @param {hallId:0,grade:0,page:1} parameter 
 */
export function getMoveLesson(parameter){
    return request({
        url: apis.GetMoveLesson,
        method: 'get',
        params: parameter
    })
}

